import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
export const query = graphql`
  {
    mdx(slug: { eq: "weight-loss-inspiration/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_first_desc
        image_first_credit_text
        image_first_credit_link
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_second_desc
        image_second_credit_text
        image_second_credit_link
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_third_desc
        image_third_credit_text
        image_third_credit_link
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        image_fourth_desc
        image_fourth_credit_text
        image_fourth_credit_link
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AffiliateLink = makeShortcode("AffiliateLink");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> At first glance, working out
  and dieting are the deciding factors in weight loss and muscle building. But
  the best plans and intentions will not help you, if you don't have the
  willpower to put them into practice. This article, therefore, is about how you
  can motivate yourself to achieve your goals and what strategies Peter used in
  particularly difficult situations to avoid going off course.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} imageDesc={props.data.mdx.frontmatter.image_first_desc} imageAuthor={props.data.mdx.frontmatter.image_first_credit_text} imageLink={props.data.mdx.frontmatter.image_first_credit_link} mdxType="Image" />
    <h2>{`The most important of all questions - Why?`}</h2>
    <p>
  Abstaining from sweets and fast food, lifting heavy weights, strenuous and
  sweaty running sessions paired with occasional hunger pangs, which is
  accompanied by unsatisfied cravings. This is by far not a complete list of
  conditions that you will be confronted with, at least in phases, if you want
  to lose weight in the long term and possibly also build up muscles. And it is
  particularly unpleasant on the so-called "bad days", when bad moods, malaise,
  bad weather and the feeling that somehow nothing wants to work out are added
  to the mix.
    </p>
    <p>
  Of course, everything is a matter of observation, a question of perspective.
  And yet, at this point, you have to ask yourself why you should step out of
  your comfort zone. Why would you put yourself through all that when life
  offers so many conveniences that are so convenient to get. Of course, there is
  a simple and pragmatic answer to this: "for the sake of my health".
  Unfortunately, we humans are programmed in such a way that this "why" is a
  poor motivator for us and makes us act only in acute cases.
    </p>
    <p>
  You will achieve your goals, you will definitely lose weight if you have good
  reasons, if you can answer the "why" for yourself. It's best to write these
  down right now and ideally place them somewhere you can see them regularly. In
  the following, Peter lets us look deep into his thoughts and describes what
  his "why" is. To help you put yourself in his shoes, the article is written in
  first-person perspective at appropriate points.
    </p>
    <h3>{`This is the reason for Peter's success`}</h3>
    <p>
  What is required externally to lose weight and build muscle, should be known
  to everyone in the basics. It is, especially in the beginning, demanding,
  exhausting, occasionally frustrating, depriving, and at the same time not
  necessarily conducive to a socially acceptable lifestyle. So I must have had a
  solid motivational base at the time of my diet to be able to pull it off in
  such a short time. In my starting position, I weighed <b>251 pounds</b> with a{" "}
  <b>height of 6.1 feet</b>. As a result, climbing stairs was anything but
  pleasant for me. I also started sweating very quickly, especially in the
  summer. Due to my specific body fat distribution, normal jeans hardly fit me;
  stretch pants also reached their limits and put correspondingly strong
  pressure on my lower body. Lethargy and fatigue were my constant companions;
  an active and conscious lifestyle looks different! Also, my outward appearance
  was anything but appealing. In photos, my face looked extremely round and
  puffy; there was no trace of cheekbones.
    </p>
    <p>
  To be honest, those physical limitations still wouldn't have motivated me
  enough to get out of my comfort zone to do what I ended up doing. It was much
  more idealistic goals and a hobby, which I practiced at the time, that
  motivated me, or rather excited me.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} imageDesc={props.data.mdx.frontmatter.image_second_desc} imageAuthor={props.data.mdx.frontmatter.image_second_credit_text} imageLink={props.data.mdx.frontmatter.image_second_credit_link} mdxType="Image" />
    <p>
  A very clichéd motivation is summer and the topless appearance in the outdoor
  pool that causes many to hit the gyms in the spring, only to throw in the
  towel shortly thereafter. I have to admit that it never interested me and I
  think it is a very poor source of motivation. It simply has no intrinsic
  value. Even as a kid, I was always doing some sort of sport, whether it was
  table tennis, skating, basketball, boxing, and ultimately working out at the
  gym. I'm particularly fond of the latter, mainly because it can be done
  independently of other people and at the same time practically regardless of
  the time of day. Also, the results are measurable, either by increasing the
  training weights and visible muscle growth. From my perspective, this sport
  has a fascinating aesthetic and an amazing simplicity about it.
    </p>
    <p>
  Finally, I decided not only to train like a fitness athlete or bodybuilder,
  but also to look roughly like one. I wanted to reach a "milestone" for myself
  or, figuratively speaking, "get to the point" at least for once. In addition,
  at the time I was lucky enough to have a good friend who was also doing
  bodybuilding, with at least the same zeal, as I did. This allowed us to engage
  in friendly competition with each other and spurred each other on. So I
  prepared everything for a diet, both in terms of training and nutrition. I was
  also "really up for it" and mentally imagined what I would probably look like
  in the foreseeable future. And yet, that initial enthusiasm would not have
  been enough to get me through the most difficult stages of dieting and
  training.
    </p>
    <h2>{`The power of habit`}</h2>
    <p>
  No matter what you want to achieve in life, it must become a habit beforehand.
  If you want to be good at playing the piano, you need to develop the habit of
  practicing the piano regularly. If you want to be a good soccer player, you
  have to play soccer regularly. This list could be continued ad infinitum, but
  I think you understand the principle. Such a habit must be as natural as
  brushing your teeth or lacing your shoes, only then it will become
  self-propelling and at the same time you will have less, or even no,
  difficulties in challenging phases.
    </p>
    <p>
  Now, to apply this principle to a dietary change, I would advise you to change
  your diet in small steps. For example, as a first step, you could change your
  breakfast to a healthy alternative. I would leave the rest of the diet
  unchanged so that you have enough time to make preparing and eating breakfast
  a habit. Since your dietary changes and your weight loss successes should be
  long-term, you can take your time with the change. For example, you might not
  target the next meal, such as lunch, for 1-2 weeks.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} imageDesc={props.data.mdx.frontmatter.image_third_desc} imageAuthor={props.data.mdx.frontmatter.image_third_credit_text} imageLink={props.data.mdx.frontmatter.image_third_credit_link} mdxType="Image" />
    <p>
  In this way, you can easily, effectively, and somewhat stress-free replace bad
  habits with good habits in a sustainable way. The most important thing to
  remember here is that it is these habits that will make the difference between
  success and failure. Even if it does not seem obvious at first glance: the
  foundation of "motivation" is habit! If you want to delve deeper into this
  topic, I recommend the book{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_atomic_habits_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_atomic_habits_text}
  </AffiliateLink>
  .
    </p>
    <h2>{`Use the fear of failure to your advantage!`}</h2>
    <p>
  Fear is a very unpleasant emotion and basically we want to avoid it. At the
  same time, fear is a very powerful emotion that can make us do things we
  wouldn't do right away or at all without it. Fear has the power to make things
  important and urgent. So if fear creates a very deep emotional state, why
  don't we just use it to our advantage?
    </p>
    <h3>{`Put it out there!`}</h3>
    <p>
  We work more effectively, more conscientiously and more decisively when we are
  accountable to someone, that is, when someone is figuratively looking over our
  shoulders. This means for you: if you want to achieve something that would be
  a great accomplishment for you, then let those around you know. Announce it to
  your family, friends, acquaintances and possibly even on social media. And do
  it with a factual determination without using relativizing phrases that you
  could use as loopholes. The advantage is obvious: if you fail now, it will not
  remain hidden and anonymous. Failure due to lack of discipline thus becomes a
  public embarrassment and for us humans, saving face with our social group is a
  big concern. This is an incredibly powerful driving force and you'll be amazed
  at how easy your diet and workouts will be from this point on!
    </p>
    <h2>{`Program your brain for success with this trick`}</h2>
    <p>
  Each of us has nerve cells or neurons in the brain that belong to the category
  of <b>mirror neurons</b>. These become active when you perform an action,
  observe it, or even think about it exclusively. This sounds a bit abstract and
  dry, which is why you are probably asking yourself how this will help you.
    </p>
    <p>
  Professional athletes and experts in their respective fields, such as chess
  players, are not only actively engaged in their profession, but also
  passively. A soccer player watches other soccer games, has his home decorated
  with soccer decorations such as jerseys, and listens to podcasts from other
  soccer players while driving. While talking to his friends, who presumably
  also play soccer, he talks about soccer. Even though the dynamics are
  completely different, chess players engage with chess itself, for example by
  watching others play and reading books about strategy. The principle behind
  these two examples should have become understandable and can be applied to any
  other thing you want to become good at or even very good at.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} imageDesc={props.data.mdx.frontmatter.image_fourth_desc} imageAuthor={props.data.mdx.frontmatter.image_fourth_credit_text} imageLink={props.data.mdx.frontmatter.image_fourth_credit_link} mdxType="Image" />
    <p>
  Through these different activities, the mirror neurons in your brain become
  active! You virtually condition yourself to perform the respective activities
  with pleasure and enthusiasm. So ideally, you can actively use this strategy
  to increase your motivation. To put this advice into action, you could
  regularly watch or listen to videos, podcasts and the like of your role
  models. Also, it helps you to write down your goals and read them regularly as
  well as visualize them.
    </p>
    <h3>{`Here's what you need to know to succeed in the long run`}</h3>
    <p>
  "Motivation" is very often seen as something static and given that successful
  people are born with. At the same time, this misconception is used as an
  excuse why something cannot be achieved. "I just don't have the motivation" or
  "if I only had your motivation, I could do it". But after reading this article
  up to this point, it should have become clear that motivation is not a genetic
  condition. Everyone can gain motivation and at the same time motivation can
  also diminish over time if it is not strengthened; an English proverb
  describes this fact very well: "what you don't use, you lose". So you are in
  control of how motivated you want to be. So work on your motivation regularly
  and achieve your goals!
    </p>
    <p>
  Although it may sound a bit cliché, practiced phrases and sayings that have a
  motivating effect on you personally can tip the scales in your favor.
  Especially during a motivational low, a depressing day, or simply in a crisis
  situation, mentally focusing on uplifting formulas can have an incredibly
  positive effect. In the following, I present some such quotes and sayings as
  examples.
    </p>
    <ul>
      <li parentName="ul">{`He who conquers himself is the mightiest warrior.`}</li>
      <li parentName="ul">{`People often say that motivation doesn't last. Well, neither does bathing - that's why we recommend it daily.`}</li>
      <li parentName="ul">{`Opportunities don't happen, you create them.`}</li>
      <li parentName="ul">{`Don't let someone else's opinion of you become your reality.`}</li>
      <li parentName="ul">{`I am not a product of my circumstances. I am a product of my decisions.`}</li>
    </ul>
    <h2>{`Great things are done by a series of small things brought together`}</h2>
    <p>
  The heading of this section is a figure of speech that summarizes the content
  of this short section very well. Simply put, this saying means that any large
  and sometimes complex problem is solvable if it is worked on in many small
  chunks. "Divide et impera" is the Latin phrase, "teile und herrsche" in
  German, and finally "divide and conquer" in English. You should have already
  encountered this strategy in the course of this text. Above I recommended you
  not to change every meal, your entire diet, at once, but to proceed
  progressively from meal to meal. This approach can make solving any kind of
  problem much easier. Apply it today as you reach your next goal!
    </p>
    <h2>{`Lift your mood with this trick`}</h2>
    <p>
  We occasionally feel uncomfortable, annoyed or dissatisfied, and all too often
  we think it's because of the things we desire but don't have. But in doing so,
  we do not realize that the reason for our discomfort is not the situation we
  are in, but our thoughts and judgments about it. Both the philosophers in
  ancient Greece and modern psychology have concluded that bad moods or even
  depression are not caused by objective facts, but by our opinions about
  circumstances. Consequently, it is not the "things" that make us unhappy, but
  solely our thoughts.
    </p>
    <p>
  This subject is very complex and extensive, so this section can only outline
  it in broad terms at best. In the following, you will nevertheless receive an
  effective strategy to be able to sustainably lift your mood and motivation.
  This comes from the stoic doctrine of ancient Greek philosophy, which is also
  used in Western psychology. This is about "gratitude", in English we speak of
  "practicing gratitude". What does gratitude mean in this context? We imagine
  things we have and focus on what it would be like if we didn't have them or
  even lost them. This applies equally to related parties and circumstances.
  This is the opposite of desire, which is imagining what it would be like to
  have things that we do not possess. Here, the former has an uplifting effect,
  while the latter leads to a negative mood. You could resolve to think of 10
  things each day that you are grateful for. Of course, you can do this silently
  in your mind, which also has a meditative effect. Interestingly, one can prove
  a positive health effect, which even has an impact on our cardiovascular
  system.
    </p>
    <p>
  This information, especially that on Stoic doctrine, comes from the book{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_stoic_philosophy_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_stoic_philosophy_text}
  </AffiliateLink>
  , which I highly recommend to you as well.
    </p>
    <h2>{`What did you learn in this article?`}</h2>
    <p>
  You have seen in this article how Peter managed to lose 66 pounds in 30 weeks
  through a mental adjustment and self-motivation and that while building muscle
  at the same time. Also, you now have a repertoire of motivational techniques
  that will help you turn your goals into reality.
    </p>
    <Disclosure mdxType="Disclosure" />
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      